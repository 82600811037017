import { FetchType } from './../models/association.class';
import { RxRestEntityDatastoreClient } from './../rx-rest-entity-datastore-client.service';
import { UriTemplate } from '../rx-rest.util';
import { Observable, zip, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { RestEndpointPersistentEntity } from '../models/rest-endpoint-persistent-entity.class';
import { RxPersistentCollection } from '../models/rx-persistent-collection.class';
import { HttpParamMap } from '../../hal/hal-client.service';
import { HttpParams } from '@angular/common/http';
import { RxRestEntity } from '../models/rx-rest-entity.class';

export class SimpleRxRestQuery {

  _urlTemplate: UriTemplate;

  constructor(
    private datasoreClient: RxRestEntityDatastoreClient,
    private entity: RestEndpointPersistentEntity<any>,
    private urlTemplate?: UriTemplate
  ) {
      this._urlTemplate = urlTemplate ? urlTemplate : entity.getUrlTemplate();
  }

  findAll<T extends RxRestEntity>(queryArguments?: HttpParamMap | HttpParams): Observable<RxPersistentCollection<T>> {
    queryArguments = queryArguments || {};
    const objectData = this.extractQueryArguments(queryArguments);
    const uri = this._urlTemplate.fillFromObject(objectData);
    const remaining = this.calculateRemainingParameters(queryArguments);
    const constructor = this.entity.getConstructor();
    return this.datasoreClient.getAll<T>(uri, constructor, { params: remaining });
  }

  find<T extends RxRestEntity>(data: Object, queryArguments?: HttpParamMap | HttpParams): Observable<T> {
    queryArguments = queryArguments || {};
    const uri = this._urlTemplate.fillFromObject(data);
    const remaining = this.calculateRemainingParameters(queryArguments);
    const constructor = this.entity.getConstructor();
    return this.datasoreClient.get(uri, constructor, { params: remaining })
    .pipe(switchMap(result => {

      const joinedProperties = [];

      let base: Observable<any> = of(result);

      base = base.pipe(switchMap(o => {
        const observables = [of(o)];

        for (const prop of this.entity.getAssociations()) {
          if (prop.getFetchType() === FetchType.EAGER) {
            const propertyName = prop.propertyName;
            joinedProperties.push(propertyName);
            observables.push(o[propertyName]);
          }
        }

        return zip(...observables);
      }));


      base = base.pipe(map(objs => {
        const entity = objs.shift();
        if (objs.length > 0) {
          let i = 0;
          for (const obj of objs) {
            const propertyName = joinedProperties[i++];
            entity[propertyName] = obj instanceof RxPersistentCollection ? obj.items : obj;
          }
        }

        return entity;
      }));

      return base;
    }));
  }

  protected extractQueryArguments(queryArguments: HttpParamMap | HttpParams): any {
    if (queryArguments instanceof HttpParams) {
      return queryArguments.keys()
      .reduce((data, key) => {
        data[key] = queryArguments.get(key);
        return data;
      }, {});
    }

    return queryArguments;
  }

  protected calculateRemainingParameters(queryArguments: HttpParamMap | HttpParams) {
    if (!queryArguments) {
      return queryArguments;
    }

    const variables = this._urlTemplate.varNames;

    if (queryArguments instanceof HttpParams) {
      const keyToRemove = queryArguments.keys()
      .filter(key => !variables.indexOf(key));

      for (const idx in keyToRemove) {
        if (queryArguments.has(keyToRemove[idx])) {
          queryArguments = queryArguments.delete(keyToRemove[idx]);
        }
      }

      return queryArguments;
    }

    return Object.keys(queryArguments)
    .filter(key => !!variables.indexOf(key))
    .reduce((obj, key) => {
      obj[key] = queryArguments[key];
      return obj;
    }, {});
  }

}
