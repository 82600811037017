
export class PagesInfo {
  pageCount: number;
  itemCountPerPage: number;
  first: number;
  current: number;
  last: number;
  previous: number;
  next: number;
  pagesInRange: any;
  firstPageInRange: number;
  lastPageInRange: number;
}
