import { RestEndpointPersistentEntity } from './rest-endpoint-persistent-entity.class';

export enum FetchType {
  EAGER = 'eager',
  LAZY = 'lazy',
}

export class Association {
  propertyName: string;
  type: any;
  associatedEntity: RestEndpointPersistentEntity<any>;
  fetchType: string = FetchType.LAZY;

  constructor(propertyName: string, type: any) {
    this.propertyName = propertyName;
    this.type = type;
  }

  setAssociatedEntity(associatedEntity: RestEndpointPersistentEntity<any>) {
    this.associatedEntity = associatedEntity;
  }

  setFetchType(type: string) {
    this.fetchType = type;
  }

  getFetchType(): string {
    return this.fetchType;
  }
}

export class ToMany extends Association {
  isLazy() {
    return this.getFetchType() === FetchType.LAZY;
  }
}


export class ToOne extends Association {

}

