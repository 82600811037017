import { StrategyInterface } from './strategy.interface';

export class DateStrategy implements StrategyInterface {
  hydrate(value: string) {
    const timestamp = Date.parse(value);

    if (isNaN(timestamp) !== false) {
      return null;
    }

    return new Date(timestamp);
  }

  extract(value: Date) {
    return value && value.toISOString ? value.toISOString() : null;
  }
}
