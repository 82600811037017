import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { HalClient } from './hal/hal-client.service';
import { RxRestEntityDatastoreClient } from './rest/rx-rest-entity-datastore-client.service';
import { RxRestEntityHydration } from './hydrator/rx-rest-entity-hydration.service';
import { RxRestEntityDatastoreClientRegistry } from './rest/rx-rest-entity-datastore-client.registry';
import { HalClientConfig, HalClientFakeConfig } from './hal/hal-client.config';

export interface RxOrmModuleConfig {
  halClientConfig?: Provider;
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    HalClient,
    RxRestEntityDatastoreClient,
    RxRestEntityHydration,
  ]
})
export class RxOrmModule {

  constructor(private restClient: RxRestEntityDatastoreClient) {
    RxRestEntityDatastoreClientRegistry.add('default', this.restClient);
  }

  static forRoot(config: RxOrmModuleConfig): ModuleWithProviders {
    return {
      ngModule: RxOrmModule,
      providers: [
        config.halClientConfig || { provide: HalClientConfig, useClass: HalClientFakeConfig },
      ]
    };
  }
}
