import { HydratorInterface } from './hydrator.interface';
import { AbstractRxRestEntityHydrator } from './abstract-rx-rest-entity-hydrator.class';
import { RxRestEntity } from '../rest/models/rx-rest-entity.class';

export class RxRestEntityObjectAssignHydrator extends AbstractRxRestEntityHydrator implements HydratorInterface {
  hydrate(data: any, entity: RxRestEntity) {
    for (const property in data) {
      if (!data.hasOwnProperty(property)) {
        continue;
      }

      entity[property] = this.hydrateValue(property, data[property], data);
    }

    return entity;
  }

  extract(entity: RxRestEntity) {
    const data = {...entity};

    for (const property in data) {
      if (!data.hasOwnProperty(property)) {
        continue;
      }

      data[property] = this.extractValue(property, data[property], data);
    }

    return data;
  }
}
