import { Observable } from 'rxjs';
import { getDefaultRxRestEntityDatastoreClient } from '../rx-rest-entity-datastore-client.registry';
import { RestEndpointPersistentEntity } from '../models/rest-endpoint-persistent-entity.class';
import { HttpParamMap } from '../../hal/hal-client.service';
import { RxRestEntityDatastoreClient } from '../rx-rest-entity-datastore-client.service';
import { HttpParams } from '@angular/common/http';
import { RxRestEntity } from '../models/rx-rest-entity.class';
import { RxPersistentCollection } from '../models/rx-persistent-collection.class';
import { RxRestDetachedCriteria } from '../rx-rest-detached-criteria';

export class RxRestStaticApi {
  constructor(
    private entity: RestEndpointPersistentEntity<any>,
  ) {}

  get<T extends RxRestEntity>(data: Object, queryArguments?: HttpParamMap | HttpParams): Observable<T> {
    const query = this.getDatastoreClient().createEntityQuery(this.entity);
    return query.find<T>(data, queryArguments);
  }

  findAll<T extends RxRestEntity>(queryArguments?: HttpParamMap | HttpParams): Observable<RxPersistentCollection<T>> {
    const query = this.getDatastoreClient().createEntityQuery(this.entity);
    return query.findAll<T>(queryArguments);
  }

  where<T extends RxRestEntity>(data: Object): RxRestDetachedCriteria<T> {
    return new RxRestDetachedCriteria<T>(this.entity, this.getDatastoreClient()).build(data);
  }

  getDatastoreClient(): RxRestEntityDatastoreClient {
    return getDefaultRxRestEntityDatastoreClient();
  }
}
