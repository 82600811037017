const _constructorRegistry = {};

export function hashCode(s: string) {
  // tslint:disable-next-line:no-bitwise
  return s.split('').reduce((a, b) => {a = ((a << 5) - a) + b.charCodeAt(0); return a & a; }, 0);
}

export function getUniqueConstructorIdentifier(constructor: any) {
  let hash = hashCode(constructor.toString());

  while (_constructorRegistry[hash] && _constructorRegistry[hash] !== constructor) {
    hash++;
  }

  _constructorRegistry[hash] = constructor;

  return hash;
}


