import { RestEndpointPersistentEntity } from './../models/rest-endpoint-persistent-entity.class';
import { RxRestStaticApi } from './rx-rest-static-api.class';
import { RxRestEntityConstructor } from './../models/rx-rest-entity.class';
import { RxRestInstanceApi } from './rx-rest-instance-api.class';
import { getUniqueConstructorIdentifier } from '../../util';

// @dynamic
export class RxRestEnhancer {

  private static STATIC_APIS: Map<number, RxRestStaticApi> = new Map();
  private static INSTANCE_APIS: Map<number, RxRestInstanceApi> = new Map();

  static registerEntity(entity: RestEndpointPersistentEntity<any>) {
    const entityName = getUniqueConstructorIdentifier(entity.getConstructor());
    // this.STATIC_APIS.set(entityName, getDefaultRxRestEntityDatastoreClient().createStaticApi(entity));
    this.STATIC_APIS.set(entityName, new RxRestStaticApi(entity));
    this.INSTANCE_APIS.set(entityName, new RxRestInstanceApi(entity));
  }

  static findStaticApi(constr: RxRestEntityConstructor<any>): RxRestStaticApi {
    return this.STATIC_APIS.get(getUniqueConstructorIdentifier(constr));
  }

  static findInstanceApi(constr: RxRestEntityConstructor<any>): RxRestInstanceApi {
    const name = getUniqueConstructorIdentifier(constr);
    if (!this.INSTANCE_APIS.has(name)) {
      throw this.typeError(constr);
    }

    return this.INSTANCE_APIS.get(name);
  }

  static typeError(constr: RxRestEntityConstructor<any>): TypeError {
    return new TypeError('Class [' + constr + '] never registred apis');
  }

}
