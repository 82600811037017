import { Association, ToMany, ToOne } from './association.class';
import { UriTemplate, URI } from './../rx-rest.util';
import { RxRestEntityConstructor, RxRestEntity } from './rx-rest-entity.class';
import { EntityMapping } from './rx-rest-entity-mapping.class';

export class RestEndpointPersistentEntity<T extends RxRestEntity> {

  protected tsClass: RxRestEntityConstructor<T>;
  protected mapping: EntityMapping;
  protected urlTemplate: UriTemplate;
  protected properties: Map<string, Association> = new Map();
  protected associationEndpoints: Map<string, UriTemplate> = new Map();

  constructor(tsClass: RxRestEntityConstructor<T>) {
    this.tsClass = tsClass;
    this.initialize();
  }

  initialize() {

    // todo: map by strategy
    if (this.tsClass.hasMany) {
      for (const propertyName in this.tsClass.hasMany) {
        if (this.tsClass.hasMany[propertyName] === undefined) {
          continue;
        }

        const constr = this.tsClass.hasMany[propertyName];
        this.addAssociation(new ToMany(propertyName, constr));
      }
    }

    if (this.tsClass.hasOne) {
      for (const propertyName in this.tsClass.hasOne) {
        if (this.tsClass.hasOne[propertyName] === undefined) {
          continue;
        }

        const constr = this.tsClass.hasOne[propertyName];
        this.addAssociation(new ToOne(propertyName, constr));
      }
    }

  }

  initializeMapping() {
    const mapping = this.getMapping();

    if (mapping.uri) {
      this.urlTemplate = URI.fromTemplate(mapping.uri);
    }

    if (!mapping.properties) {
      return;
    }

    for (const property in mapping.properties) {
      if (!this.hasPropertyByName(property)) {
        continue;
      }

      const association = this.getPropertyByName(property);
      const mappingInfo =  mapping.properties[property];

      if (association && mappingInfo.fetch) {
        association.setFetchType(mappingInfo.fetch);
      }

      const uriStr = mappingInfo.uri;

      if (uriStr) {
        const template = URI.fromTemplate(uriStr);
        this.associationEndpoints.set(property, template);
      } else {
        // TODO
      }
    }

  }

  addAssociation(association: Association) {
    this.properties.set(association.propertyName, association);
  }

  setMapping(mapping: EntityMapping) {
    this.mapping = mapping;
    this.initializeMapping();
  }

  getConstructor(): RxRestEntityConstructor<T> {
    return this.tsClass;
  }

  getMapping(): EntityMapping {
    return this.mapping;
  }

  getUrlTemplate(): UriTemplate {
    return this.urlTemplate;
  }

  getAssociations(): Array<Association> {
    return Array.from(this.properties.values());
  }

  getPropertyByName(name: string): Association {
    return this.properties.get(name);
  }

  hasPropertyByName(name: string): boolean {
    return this.properties.has(name);
  }

  getAssociationTemplate(name: string): UriTemplate {
    return this.associationEndpoints.get(name);
  }
}
