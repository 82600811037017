import { Association } from './models/association.class';
import { StaticRxRestEntityMappingRegistry } from './rx-rest-entity-mapping.registry';
import { RestEndpointPersistentEntity } from './models/rest-endpoint-persistent-entity.class';
import { RxRestEntityConstructor } from './models/rx-rest-entity.class';
// import { initializeIdentifiableClass } from '../util';

export function RxRestEmbeddedEntity(embeddedConstructor: RxRestEntityConstructor<any>) {
  return function decorate(obj, propertyName: string) {

    if (!StaticRxRestEntityMappingRegistry.has(obj.constructor)) {
      StaticRxRestEntityMappingRegistry.add(new RestEndpointPersistentEntity(obj.constructor));
    }

    const entity = StaticRxRestEntityMappingRegistry.get(obj.constructor);

    if (!StaticRxRestEntityMappingRegistry.has(embeddedConstructor)) {
      StaticRxRestEntityMappingRegistry.add(new RestEndpointPersistentEntity(embeddedConstructor));
    }

    const associatedEntity = StaticRxRestEntityMappingRegistry.get(embeddedConstructor);

    const association = new Association(propertyName, embeddedConstructor);
    association.setAssociatedEntity(associatedEntity);

    entity.addAssociation(association);
  };
}
