import { GetDataFunc } from './get-data.interface';

export type AutoReloadHandling = 'always' | 'auto' | 'never';

export interface SettingsInterface<T> {
  autoreload?: AutoReloadHandling;
  pageRange?: number;
  total?: number;
  getData?: GetDataFunc<T>;
}

export class Settings<T> {
  getData: GetDataFunc<T>;

  total = 0;

  autoreload: AutoReloadHandling = 'auto';

  pageRange = 10;

  static merge<T>(existing: Settings<T>, newSettings: SettingsInterface<T>): Settings<T> {
    Object.assign(existing, newSettings);
    return existing;
  }
}
