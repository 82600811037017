import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { HalResource, HalResourceInterface } from './hal-resource';
import { HalClientConfig } from './hal-client.config';

export interface HttpParamMap { [param: string]: string | string[]; }

export interface HalRequestOptions {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  params?: HttpParams | HttpParamMap;
}

@Injectable()
export class HalClient {

  constructor(
    private httpClient: HttpClient,
    private config: HalClientConfig
  ) {}

  get(url: string, options?: HalRequestOptions): Observable<HalResource> {
    return this.config.prepareUrl(url)
    .pipe(switchMap(combinedUrl => this.httpClient.get<HalResourceInterface>(combinedUrl, options)))
    .pipe(map(data => HalResource.instanceFromData(data)));
  }

  post(url: string, entity: Object, options?: HalRequestOptions): Observable<HalResource> {
    return this.config.prepareUrl(url)
    .pipe(switchMap(combinedUrl => this.httpClient.post<HalResourceInterface>(combinedUrl, entity, options)))
    .pipe(map(data => HalResource.instanceFromData(data)));
  }

  patch(url: string, entity: Object, options?: HalRequestOptions): Observable<HalResource> {
    return this.config.prepareUrl(url)
    .pipe(switchMap(combinedUrl => this.httpClient.patch<HalResourceInterface>(combinedUrl, entity, options)))
    .pipe(map(data => HalResource.instanceFromData(data)));
  }

  put(url: string, entity: Object, options?: HalRequestOptions): Observable<HalResource> {
    return this.config.prepareUrl(url)
    .pipe(switchMap(combinedUrl => this.httpClient.put<HalResourceInterface>(combinedUrl, entity, options)))
    .pipe(map(data => HalResource.instanceFromData(data)));
  }

  delete(url: string, options?: HalRequestOptions): Observable<HalResource|boolean> {
    return this.config.prepareUrl(url)
    .pipe(switchMap(combinedUrl => this.httpClient.delete<HalResourceInterface|boolean>(combinedUrl, options)))
    .pipe(map(data => typeof data === 'object' ? HalResource.instanceFromData(data) : data));
  }
}
