import { RxRestEnhancer } from './../api/rx-rest-enhancer.registry';
import { RxRestStaticApi } from './../api/rx-rest-static-api.class';

import { RxRestInstanceApi } from '../api/rx-rest-instance-api.class';
import { HttpParamMap } from '../../hal/hal-client.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RxPersistentCollection } from './rx-persistent-collection.class';
import { RxRestDetachedCriteria } from '../rx-rest-detached-criteria';
import { StaticRxRestEntityMappingRegistry } from '../rx-rest-entity-mapping.registry';

export interface RxRestEntityConstructor<T extends RxRestEntity>  {
  new (...args: any[]): T;
  hasMany?: any;
  hasOne?: any;
}

// @dynamic
export class RxRestEntity {

  private _fromServer = false;
  private _links = {};

  [x: string]: any;

  static findAll<T extends RxRestEntity>(params?: HttpParamMap | HttpParams): Observable<RxPersistentCollection<T>> {
    return this.currentRestStaticApi().findAll<T>(params);
  }

  static get<T extends RxRestEntity>(data: Object, params?: HttpParamMap | HttpParams): Observable<T> {
    return this.currentRestStaticApi().get<T>(data, params);
  }

  static where<T extends RxRestEntity>(data: Object): RxRestDetachedCriteria<T> {
    return this.currentRestStaticApi().where<T>(data);
  }

  protected static currentRestStaticApi(): RxRestStaticApi {
    return RxRestEnhancer.findStaticApi(this);
  }

  protected static currentRestInstanceApi(): RxRestInstanceApi {
    return RxRestEnhancer.findInstanceApi(this);
  }

  fromServer(): boolean;
  fromServer(fromServer: boolean): this;
  fromServer(fromServer?: boolean) {
    if (fromServer === undefined) {
      return this._fromServer;
    }

    this._fromServer = fromServer;
    return this;
  }

  links(): {[x: string]: any} {
    return this._links;
  }

  hasLink(relation: string): boolean {
    return !!this._links[relation];
  }

  getLink(relation: string): any {
    return this._links[relation];
  }

  setLink(relation: string, data: any): this {
    this._links[relation] = data;
    return this;
  }

  fromJson(data: Object): this {
    Object.assign(this, data);
    return this;
  }

  save(params?: HttpParamMap, query?: HttpParams, url?: string) {
    url = url || StaticRxRestEntityMappingRegistry.get(this.constructor).getMapping().uri;
    return ((this.constructor as any).currentRestInstanceApi() as RxRestInstanceApi).save(url, this, params, query);
  }

  patch(params?: HttpParamMap, query?: HttpParams, url?: string) {
    url = url || StaticRxRestEntityMappingRegistry.get(this.constructor).getMapping().uri;
    return ((this.constructor as any).currentRestInstanceApi() as RxRestInstanceApi).patch(url, this, params, query);
  }

  post(params?: HttpParamMap, query?: HttpParams, url?: string) {
    url = url || StaticRxRestEntityMappingRegistry.get(this.constructor).getMapping().uri;
    return ((this.constructor as any).currentRestInstanceApi() as RxRestInstanceApi).post(url, this, params, query);
  }

  delete(params?: HttpParamMap, query?: HttpParams, url?: string) {
    url = url || StaticRxRestEntityMappingRegistry.get(this.constructor).getMapping().uri;
    return ((this.constructor as any).currentRestInstanceApi() as RxRestInstanceApi).delete(url, this, params, query);
  }

  customPost(endpoint: string, body: Object) {
    return ((this.constructor as any).currentRestInstanceApi() as RxRestInstanceApi).customPost(this, endpoint, body);
  }

  customPut(endpoint: string, body: Object) {
    return ((this.constructor as any).currentRestInstanceApi() as RxRestInstanceApi).customPut(this, endpoint, body);
  }
}
