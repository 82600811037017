import { StaticRxRestEntityHydratorRegistry } from './rx-rest-entity-hydrator.registry';

export function RxRestEntityHydratorStrategy(strategyConstructor: any) {
  return function decorate(obj, propertyName: string) {
    const hydrator = StaticRxRestEntityHydratorRegistry.get(obj.constructor) as any;
    if (hydrator.addStrategy instanceof Function) { // ugly workaround
      hydrator.addStrategy(propertyName, new strategyConstructor());
    }
  };
}
