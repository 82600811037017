import { RestEndpointPersistentEntity } from './models/rest-endpoint-persistent-entity.class';
import { getUniqueConstructorIdentifier } from '../util';

// @dynamic
export class StaticRxRestEntityMappingRegistry {

  private static registry: Map<number, RestEndpointPersistentEntity<any>> = new Map();

  static add(entity: RestEndpointPersistentEntity<any>): RestEndpointPersistentEntity<any> {
    if (!this.has(entity.getConstructor())) {
      this.registry.set(getUniqueConstructorIdentifier(entity.getConstructor()), entity);
    }

    return entity;
  }

  static get(ctr: any): RestEndpointPersistentEntity<any> {
    return this.registry.get(getUniqueConstructorIdentifier(ctr));
  }

  static has(ctr: any): boolean {
    return !!this.registry.has(getUniqueConstructorIdentifier(ctr));
  }

}
