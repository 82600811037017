import { NgModule } from '@angular/core';

import { NgxDataListDirective } from './ngx-datalist.directive';

@NgModule({
  imports: [
  ],
  declarations: [
    NgxDataListDirective,
  ],
  entryComponents: [

  ],
  exports: [
    NgxDataListDirective,
  ]
})
export class NgxDatalistModule { }
