import { Observable } from 'rxjs';
import { RxRestEntityHydration } from './../hydrator/rx-rest-entity-hydration.service';
import { RxRestEntityConstructor, RxRestEntity } from './models/rx-rest-entity.class';
import { URI, UriTemplate } from './rx-rest.util';
import { RxRestEntityDatastoreClient } from './rx-rest-entity-datastore-client.service';
import { RestEndpointPersistentEntity } from './models/rest-endpoint-persistent-entity.class';
import { RxRestEnhancer } from './api/rx-rest-enhancer.registry';
import { Association, ToOne, ToMany } from './models/association.class';
import { RxRestStaticApi } from './api/rx-rest-static-api.class';
import { StaticRxRestEntityMappingRegistry } from './rx-rest-entity-mapping.registry';
import { SimpleRxRestQuery } from './query/simple-rx-rest-query.class';
import { RxPersistentCollection } from './models/rx-persistent-collection.class';
import { HalResource } from '../hal/hal-resource';


export class RestEntityCodec {

  constructor(
    private entity: RestEndpointPersistentEntity<any>,
    private hydration: RxRestEntityHydration
  ) {}

  static create(constr: RxRestEntityConstructor<any>, hydration: RxRestEntityHydration) {
    return new RestEntityCodec(StaticRxRestEntityMappingRegistry.get(constr), hydration);
  }

  fillCollectionFromResource<T extends RxRestEntity>(collection: RxPersistentCollection<T>, entity: T, resource: HalResource) {
    const data = {...resource};
    const embedded = resource.getEmbedded();
    const links = resource.getLinks();
    delete data._links;
    delete data._embedded;

    // handle links
    for (const linkName in links) {
      if (!links.hasOwnProperty(linkName)) {
        continue;
      }

      // store link
      const link = links[linkName];
      collection.setLink(linkName, link);
    }

    data.items = [];
    Object.keys(embedded).forEach(name => {
      for (const itemResource of embedded[name]) {
        data.items.push(this.fillFromResource(new (entity.constructor as any)(), itemResource));
      }
    });

    collection.fromJson(data);

    return collection;
  }

  fillFromResource(instance: RxRestEntity, resource: HalResource) {

    const staticApi: RxRestStaticApi = RxRestEnhancer.findStaticApi(instance.constructor as any);
    const datastoreClient: RxRestEntityDatastoreClient = staticApi.getDatastoreClient();

    const data = {...resource};
    const embedded = resource.getEmbedded();
    const links = resource.getLinks();
    delete data._links;
    delete data._embedded;

    // hydrate embedded resources
    for (const key in embedded) {
        if (!embedded.hasOwnProperty(key)) {
          continue;
        }

        const property = this.entity.getPropertyByName(key);

        // ignore not configured embedded data
        if (!(property instanceof Association)) {
          continue;
        }

        // handle toMany and toOne associations
        const toMany = property instanceof ToMany;
        if (toMany || property instanceof ToOne) {
          const link = embedded[key].getLink('self');
          let uriStr = link.href;
          if (uriStr.startsWith('http')) {
            uriStr = (new URL(uriStr)).pathname;
          }

          const urlTemplate = URI.fromTemplate(uriStr);

          const query = this.prepareQuery(datastoreClient, property, urlTemplate);

          if (toMany) {
            data[key] = datastoreClient.proxyMany(query, link);
          } else {
            data[key] = datastoreClient.proxy(query, link);
          }
          continue;
        }

        // if property has no associated entity defined ignore
        if (!property.associatedEntity) {
          continue;
        }

        const type = property.associatedEntity.getConstructor();

        // handle embedded collections
        if (embedded[key] instanceof Array) {
          const items = [];
          for (const item of embedded[key]) {
            const embCodec = RestEntityCodec.create(type, this.hydration);
            items.push(embCodec.fillFromResource(new type(), item));
          }
          data[key] = items;
          continue;
        }

        // hande embedded entities
        const codec = RestEntityCodec.create(type, this.hydration);
        data[key] = codec.fillFromResource(new type(), embedded[key]);
    }

    // handle links
    for (const associationName in links) {
      if (!links.hasOwnProperty(associationName)) {
        continue;
      }

      // store link
      const link = links[associationName];
      instance.setLink(associationName, link);

      // association was already set by embedded param
      if (data[associationName]) {
        continue;
      }

      // check for defined association
      const property = this.entity.getPropertyByName(associationName);
      if (!(property instanceof Association)) {
        continue;
      }

      // handle toMany and toOne associations from links
      let uriStr = link.href;
      if (uriStr.startsWith('http')) {
        uriStr = (new URL(uriStr)).pathname;
      }

      const urlTemplate = URI.fromTemplate(uriStr);
      const query = this.prepareQuery(datastoreClient, property, urlTemplate);
      const toMany = property instanceof ToMany;

      if (toMany) {
        data[associationName] = datastoreClient.proxyMany(query, link);
      } else {
        data[associationName] = datastoreClient.proxy(query, link);
      }
    }

    // loose associations => only defined in mapping
    for (const association of this.entity.getAssociations()) {

      const propertyName = association.propertyName;
      if (embedded && embedded[propertyName]) { // overwrite only if there was no embedded data
        continue;
      }

      const property = this.entity.getPropertyByName(propertyName);
      let associatedTemplate = this.entity.getAssociationTemplate(propertyName);
      if (!associatedTemplate) {
        continue;
      }

      associatedTemplate = URI.fromTemplate(associatedTemplate.fillFromObject(data));

      const query = this.prepareQuery(datastoreClient, association, associatedTemplate);

      if (property && property instanceof ToMany) {
        data[propertyName] = datastoreClient.proxyMany(query);
      } else {
        data[propertyName] = datastoreClient.proxy(query);
      }
    }

    instance.fromServer(true);

    return this.hydration.hydrate(instance, data);
  }

  prepareQuery(datastoreClient: RxRestEntityDatastoreClient, association: Association, urlTemplate: UriTemplate): SimpleRxRestQuery {
    const query = datastoreClient.createQuery(association.type, urlTemplate);
    return query;
  }

  extractFromEntity<T extends RxRestEntity>(instance: T): Object {
    const data = this.hydration.extract(instance);
    delete data._fromServer;
    delete data._links;

    for (const property in data) {
      if (!data.hasOwnProperty(property)) {
        continue;
      }

      const child = data[property];

      if (child instanceof Observable) {
        delete data[property];
        continue;
      }

      if (child instanceof Array) {
        data[property] = [];
        for (const index in child) {
          if (child[index] === undefined) {
            continue;
          }

          let value = child[index];
          if (value instanceof Object) {
            const codec = RestEntityCodec.create(<any> child.constructor, this.hydration);
            value = codec.extractFromEntity(value);
          }
          data[property][index] = value;
        }
        continue;
      }

      if (child instanceof Object) {
        const codec = RestEntityCodec.create(<any> child.constructor, this.hydration);
        data[property] = codec.extractFromEntity(child);
      }
    }

    return data;
  }

}
