import * as uriTemplates from 'uri-templates';

export interface UriTemplate {
  varNames: Array<string>;
  template: string;
  fillFromObject(data): string;
  toString(): string;
}

export class URI {
  static fromTemplate(templateUri: string): UriTemplate {
    return new uriTemplates(templateUri);
  }

  static expandTemplate(templatedUri: string, vars: any): string {
    return URI.fromTemplate(templatedUri).fillFromObject(vars); // use third party RFC 6570 URI Template lib
  }
}
