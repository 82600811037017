import { RxRestEntityDatastoreClient } from './rx-rest-entity-datastore-client.service';

// @dynamic
export class RxRestEntityDatastoreClientRegistry {

  private static registry: { [name: string]: RxRestEntityDatastoreClient; } = {};

  static add(key: string, client: RxRestEntityDatastoreClient): void {
    this.registry[key] = client;
  }

  static get(key: string): RxRestEntityDatastoreClient {
    return this.registry[key];
  }
}

export function getDefaultRxRestEntityDatastoreClient(): RxRestEntityDatastoreClient {
  return RxRestEntityDatastoreClientRegistry.get('default');
}
