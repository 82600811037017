import { UrlEncoderInterface } from '../ngx-datalist';
import { ParamValues } from '../models/param';

export class AngularRouterUrlEncoder implements UrlEncoderInterface {
  encodeParams(params: ParamValues<any>) {
    const pairs: { [name: string]: string } = {};

    for (const name in params) {
      if (!params.hasOwnProperty(name)) {
        continue;
      }

      const value = (params as { [name: string]: any })[name];

      if (typeof value === 'object') {
        for (const subkey in value) {

          if (!value.hasOwnProperty(subkey)) {
            continue;
          }

          const pname = name + '[' + ( subkey ) + ']';
          const subvalue = value[subkey];
          pairs[pname] = subvalue;
        }
      } else {
        pairs[name] = value;
      }
    }

    return pairs;
  }
}
