import { ScrollingStyleInterface, NgxDataList } from '../ngx-datalist';

export class ScrollingStyleJumping implements ScrollingStyleInterface {
  getPages(dataList: NgxDataList<any>, pageRange?: number): number[] {
    pageRange = dataList.settings().pageRange;
    const pageNumber = dataList.page();

    let delta = pageNumber % pageRange;

    if (delta === 0) {
      delta = pageRange;
    }

    const offset = pageNumber - delta;
    const lowerBound = offset + 1;
    const upperBound = offset + pageRange;

    return dataList.getPagesInRange(lowerBound, upperBound);
  }
}
