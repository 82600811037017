import { Observable, Operator } from 'rxjs';

export class ProxyObservable<T> extends Observable<T> {

  private _data = {};

  static from<T>(source: any) {
    const observable = new ProxyObservable<T>();
    (observable as any).source = <Observable<T>> source;
    return observable;
  }

  lift<R>(operator: Operator<T, R>): Observable<R> {
    const observable = new ProxyObservable<R>();
    (<any>observable).source = this;
    (<any>observable).operator = operator;
    return observable;
  }

  getData(): any {
    return this._data;
  }

  setData(data: any) {
    this._data = data;
    return this;
  }

}
