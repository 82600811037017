import { FilterValues } from './filter';
import { SortingValues } from './sorting';

export interface ParamValuesInterface {
  page?: number;
  count?: number;
  filter?: FilterValues;
  sorting?: SortingValues;
}

export class ParamValues<T> {
  page = 1;
  count = 10;
  filter: FilterValues = {};
  sorting: SortingValues = {};
}
