import { RxRestEntity } from './rx-rest-entity.class';

export class RxPersistentCollection<T extends RxRestEntity> implements IterableIterator<T> {

  private pointer = 0;

  page: 1;
  pageCount: 0;
  pageSize: 0;
  totalItems: 0;

  private _links = {};

  links(): {[x: string]: any} {
    return this._links;
  }

  hasLink(relation: string): boolean {
    return !!this._links[relation];
  }

  getLink(relation: string): any {
    return this._links[relation];
  }

  setLink(relation: string, data: any): this {
    this._links[relation] = data;
    return this;
  }

  constructor(public items: Array<T> = []) {}

  at(index: number): T { return this.items[index]; }

  get length(): number { return this.items.length; }

  fromJson(data: Object): this {
    Object.assign(this, data);
    return this;
  }

  public reset(): void {
    this.pointer = 0;
  }

  public next(): IteratorResult<T> {
    if (this.pointer < this.items.length) {
      return {
        done: false,
        value: this.items[this.pointer++]
      };
    }

    return {
      done: true,
      value: null
    };
  }

  [Symbol.iterator](): IterableIterator<T> {
    // this.reset();
    return this;
  }

}
