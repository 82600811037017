import { NgxDataList } from '../ngx-datalist';
import { ScrollingStyleSliding } from './sliding';

export class ScrollingStyleElastic extends ScrollingStyleSliding {
  getPages(dataList: NgxDataList<any>): number[] {
    let pageRange = dataList.settings().pageRange;
    const pageNumber = dataList.page();

    const pageCount = dataList.pageCount();

    const originalPageRange = pageRange;
    pageRange = pageRange * 2 - 1;

    if (originalPageRange + pageNumber - 1 < pageRange) {
      pageRange = originalPageRange + pageNumber - 1;
    } else if (originalPageRange + pageNumber - 1 > pageCount) {
      pageRange = originalPageRange + pageCount - pageNumber;
    }

    return super.getPages(dataList, pageRange);
  }
}
