import { RxRestEntityDatastoreClient } from './../rx-rest-entity-datastore-client.service';
import { Observable } from 'rxjs';
import { getDefaultRxRestEntityDatastoreClient } from '../rx-rest-entity-datastore-client.registry';
import { RestEndpointPersistentEntity } from '../models/rest-endpoint-persistent-entity.class';
import { RxRestEntity } from '../models/rx-rest-entity.class';
import { HttpParamMap } from '../../hal/hal-client.service';
import { HttpParams } from '@angular/common/http';

export class RxRestInstanceApi {
  constructor(
    private entity: RestEndpointPersistentEntity<any>,
  ) {}

  save<T extends RxRestEntity>(url: string, instance: T, params?: HttpParamMap, query?: HttpParams): Observable<T> {
    return this.getDatastoreClient().persist<T>(url, instance, params, { params: query });
  }

  patch<T extends RxRestEntity>(url: string, instance: T, params?: HttpParamMap, query?: HttpParams): Observable<T> {
    return this.getDatastoreClient().patch<T>(url, instance, params, { params: query });
  }

  post<T extends RxRestEntity>(url: string, instance: T, params?: HttpParamMap, query?: HttpParams): Observable<T> {
    return this.getDatastoreClient().post<T>(url, instance, params, { params: query });
  }

  // insert<T extends RxRestEntity>(instance: T): Observable<T> { // TODO
  //   return this.getDatastoreClient().insert<T>(instance);
  // }

  delete<T extends RxRestEntity>(url: string, instance: T, params?: HttpParamMap, query?: HttpParams): Observable<T|boolean> {
    return this.getDatastoreClient().delete<T>(url, instance, params, { params: query });
  }

  customPost<T extends RxRestEntity>(instance: T, endpoint: string, body: Object, params?: HttpParamMap, query?: HttpParams): Observable<T> {
    return this.getDatastoreClient().customPost<T>(instance, endpoint, body, params, { params: query });
  }

  customPut<T extends RxRestEntity>(instance: T, endpoint: string, body: Object, params?: HttpParamMap, query?: HttpParams): Observable<T> {
    return this.getDatastoreClient().customPut<T>(instance, endpoint, body, params, { params: query });
  }

  getDatastoreClient(): RxRestEntityDatastoreClient {
    return getDefaultRxRestEntityDatastoreClient();
  }
}
