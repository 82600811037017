import { Directive, Input, OnDestroy } from '@angular/core';
import { NgxDataList } from './ngx-datalist';

@Directive({
  selector: '[ngxDataList]',
  exportAs: 'dataList'
})
export class NgxDataListDirective<T> implements OnDestroy {

  @Input() ngxDataList: NgxDataList<T>;

  // shortcut for data
  get $data(): T[] {
    return this.ngxDataList.data;
  }

  ngOnDestroy(): void {
    this.ngxDataList.destroy();
  }
}
