import { Injectable } from '@angular/core';
import { RxRestEntity } from './../rest/models/rx-rest-entity.class';
import { StaticRxRestEntityHydratorRegistry } from './rx-rest-entity-hydrator.registry';


@Injectable()
export class RxRestEntityHydration {

  hydrate<T extends RxRestEntity>(entity: T, data: any) {
    return StaticRxRestEntityHydratorRegistry.get(entity.constructor).hydrate(data, entity);
  }

  extract<T extends RxRestEntity>(entity: T): any {
    return StaticRxRestEntityHydratorRegistry.get(entity.constructor).extract(entity);
  }

}
