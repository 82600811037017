import { UrlEncoderInterface } from '../ngx-datalist';
import { ParamValues } from '../models/param';

export class PhpUrlEncoder implements UrlEncoderInterface {
  encodeParams(params: ParamValues<any>) {
    const pairs: { [name: string]: string } = {};

    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }

      const value = (params as { [name: string]: any })[key];
      const name = key;

      if (typeof value === 'object') {
        for (const subkey in value) {
          if (!value.hasOwnProperty(subkey)) {
            continue;
          }

          const pname = name + '[' + subkey + ']';
          const subvalue = value[subkey];

          if (Array.isArray(subvalue)) {
            subvalue.forEach((singleval, index) => {
              pairs[pname + '[' + index + ']'] = singleval;
            });
            continue;
          }

          pairs[pname] = subvalue;
        }
      } else {
        if (Array.isArray(value)) {
          value.forEach((singleval, index) => {
            pairs[name + '[' + index + ']'] = singleval;
          });
          continue;
        }

        pairs[name] = value;
      }
    }

    return pairs;
  }
}
