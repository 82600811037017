import { RestEndpointPersistentEntity } from './models/rest-endpoint-persistent-entity.class';
import { StaticRxRestEntityMappingRegistry } from './rx-rest-entity-mapping.registry';
import { RxRestEntityConstructor, RxRestEntity } from './models/rx-rest-entity.class';
import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';
import { map, concatAll  } from 'rxjs/operators';

import { RxRestEntityHydration } from '../hydrator/rx-rest-entity-hydration.service';

import { UriTemplate, URI } from './rx-rest.util';
import { SimpleRxRestQuery } from './query/simple-rx-rest-query.class';
import { RestEntityCodec } from './rest-entity-codec.class';

import { BatchOperation } from './batch/batch-operation.class';
import { HalClient, HalRequestOptions, HttpParamMap } from '../hal/hal-client.service';
import { RxPersistentCollection } from './models/rx-persistent-collection.class';
import { HalResource } from '../hal/hal-resource';
import { ProxyObservable } from './proxy/proxy-observable.class';
import { PersistInstance } from './persist-instance.interface';

@Injectable()
export class RxRestEntityDatastoreClient {

  constructor(
    private halClient: HalClient,
    private hydration: RxRestEntityHydration
  ) {}

  getAll<T extends RxRestEntity>(url: string, constr: RxRestEntityConstructor<any>, options?: HalRequestOptions)
  : Observable<RxPersistentCollection<T>> {
    const instance = (new constr());
    return this.halClient.get(url, options)
    .pipe(map(data => this.createRestEntityCodec(instance).fillCollectionFromResource<T>(new RxPersistentCollection<T>(), instance, data)));
  }

  get<T extends RxRestEntity>(url: string, constr: RxRestEntityConstructor<any>, options?: HalRequestOptions): Observable<T> {
    const instance = (new constr());
    return this.halClient.get(url, options)
    .pipe(map(data => this.createRestEntityCodec(instance).fillFromResource(instance, data)));
  }

  post<T extends RxRestEntity>(url: string, entity: T, params?: HttpParamMap, options?: HalRequestOptions): Observable<T> {
    const codec = this.createRestEntityCodec(entity);
    const body = codec.extractFromEntity(entity);
    params = Object.assign(entity, params);
    const finalUrl = URI.expandTemplate(url, params);

    return this.halClient.post(finalUrl, body, options)
    .pipe(map(data => codec.fillFromResource(entity, data)));
  }

  patch<T extends RxRestEntity>(url: string, entity: T, params?: HttpParamMap, options?: HalRequestOptions): Observable<T> {
    const codec = this.createRestEntityCodec(entity);
    const body = codec.extractFromEntity(entity);
    params = Object.assign(entity, params);
    const finalUrl = URI.expandTemplate(url, params);

    return this.halClient.patch(finalUrl, body, options)
    .pipe(map(data => codec.fillFromResource(entity, data)));
  }

  customPost<T extends RxRestEntity>(entity: T, endpoint: string, body: Object, params?: HttpParamMap, options?: HalRequestOptions): Observable<T> {
    const uriTemplate = StaticRxRestEntityMappingRegistry.get(entity.constructor).getUrlTemplate();

    params = Object.assign(entity, params);
    const url = uriTemplate.fillFromObject(params) + endpoint;

    return this.halClient.post(url, body, options)
    .pipe(map(data => this.createRestEntityCodec(entity).fillFromResource(entity, data)));
  }

  put<T extends RxRestEntity>(url: string, entity: T, params?: HttpParamMap, options?: HalRequestOptions): Observable<T> {
    const codec = this.createRestEntityCodec(entity);
    const body = codec.extractFromEntity(entity);
    params = Object.assign(entity, params);
    const finalUrl = URI.expandTemplate(url, params);

    return this.halClient.put(finalUrl, body, options)
    .pipe(map(data => codec.fillFromResource(entity, data)));
  }

  customPut<T extends RxRestEntity>(entity: T, endpoint: string, body: Object, params?: HttpParamMap, options?: HalRequestOptions): Observable<T> {
    const uriTemplate = StaticRxRestEntityMappingRegistry.get(entity.constructor).getUrlTemplate();

    params = Object.assign(entity, params);
    const url = uriTemplate.fillFromObject(params) + endpoint;

    return this.halClient.put(url, body, options)
    .pipe(map(data => this.createRestEntityCodec(entity).fillFromResource(entity, data)));
  }

  delete<T extends RxRestEntity>(url: string, entity: T, params?: HttpParamMap, options?: HalRequestOptions): Observable<T|boolean> {
    params = Object.assign(entity, params);
    const finalUrl = URI.expandTemplate(url, params);
    return this.halClient.delete(finalUrl, options)
    .pipe(map(data => data instanceof HalResource ? this.createRestEntityCodec(entity).fillFromResource(entity, data) : true));
  }

  persist<T extends RxRestEntity>(url: string, entity: T, params?: HttpParamMap, options?: HalRequestOptions): Observable<T> {
    return this.persistAll([{url: url, instance: entity, params: params, options: options }]);
  }

  persistAll(instances: PersistInstance[]): Observable<any> {
    return this.persistAllInternal(instances);
  }

  protected createRestEntityCodec(entity): RestEntityCodec {
    return RestEntityCodec.create(entity.constructor, this.hydration);
  }

  proxy(query: SimpleRxRestQuery, halLink?: any): ProxyObservable<any> {
    const proxy = ProxyObservable.from(query.find({}));
    proxy.setData(halLink);
    return proxy;
  }

  proxyMany(query: SimpleRxRestQuery, halLink?: any): ProxyObservable<any> {
    const proxy = ProxyObservable.from(query.findAll());
    proxy.setData(halLink);
    return proxy;
  }

  createEntityQuery(entity: RestEndpointPersistentEntity<any>) {
    const query = new SimpleRxRestQuery(this, entity);
    return query;
  }

  createQuery(type: any, urlTemplate?: UriTemplate) {
    const entity = StaticRxRestEntityMappingRegistry.get(type);
    const query = new SimpleRxRestQuery(this, entity, urlTemplate);
    return query;
  }

  protected persistAllInternal(instances: PersistInstance[]) {

    const batchOperation = new BatchOperation();

    for (const instance of instances) {
      const entity = StaticRxRestEntityMappingRegistry.get(instance.instance.constructor);
      if (instance.instance.fromServer()) {
        batchOperation.addUpdate(entity, instance);
      } else {
        batchOperation.addInsert(entity, instance);
      }
    }

    if (batchOperation.hasPendingRequests()) {
      return this.batchWrite(batchOperation);
    } else {
      return;
    }

  }

  protected batchWrite(batchOperation: BatchOperation) {

    const observables: Observable<any>[] = [];

    batchOperation.inserts.forEach((objects: PersistInstance[], entity: RestEndpointPersistentEntity<any>) => {
      for (const instance of objects) {
        observables.push(this.post(instance.url, instance.instance as RxRestEntity, instance.params, instance.options));
      }
    });

    batchOperation.updates.forEach((objects: PersistInstance[], entity: RestEndpointPersistentEntity<any>) => {
      for (const instance of objects) {
        observables.push(this.put(instance.url, instance.instance as RxRestEntity, instance.params, instance.options));
      }
    });

    const base = from(observables)
    .pipe(concatAll());

    return base;
  }

}
