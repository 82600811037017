import { RxRestEnhancer } from './api/rx-rest-enhancer.registry';
import { StaticRxRestEntityMappingRegistry } from './rx-rest-entity-mapping.registry';
import { EntityMapping } from './models/rx-rest-entity-mapping.class';
import { RestEndpointPersistentEntity } from './models/rest-endpoint-persistent-entity.class';
import { RxRestEntityConstructor } from './models/rx-rest-entity.class';
// import { initializeIdentifiableClass } from '../util';

export function RxRestEntityMapping(metadata?: EntityMapping) {
  return function decorate(constructor: RxRestEntityConstructor<any>) {

    if (!StaticRxRestEntityMappingRegistry.has(constructor)) {
      StaticRxRestEntityMappingRegistry.add(new RestEndpointPersistentEntity(constructor));
    }

    const entity = StaticRxRestEntityMappingRegistry.get(constructor);

    if (metadata) {
      entity.setMapping(metadata);
    }

    RxRestEnhancer.registerEntity(entity);
  };
}
