import { RestEndpointPersistentEntity } from './models/rest-endpoint-persistent-entity.class';
import { URI } from './rx-rest.util';
import { RxRestEntity } from './models/rx-rest-entity.class';
import { Observable } from 'rxjs';
import { RxPersistentCollection } from './models/rx-persistent-collection.class';
import { SimpleRxRestQuery } from './query/simple-rx-rest-query.class';
import { RxRestEntityDatastoreClient } from './rx-rest-entity-datastore-client.service';


/**
 * This is a really basic implementation => TODO: enhance
 */
export class RxRestDetachedCriteria<T extends RxRestEntity> {

  with: any;

  constructor(
    private entity: RestEndpointPersistentEntity<any>,
    private client: RxRestEntityDatastoreClient,
  ) {}

  build(data: Object): RxRestDetachedCriteria<T> {
    this.with = data;
    return this;
  }

  find(args: any): Observable<T> {
    const query = this.prepareQuery();
    query._urlTemplate = URI.fromTemplate(args.uri);
    return query.find<T>(this.with, this.with);
  }

  findAll(args: any): Observable<RxPersistentCollection<T>> {
    const query = this.prepareQuery();
    query._urlTemplate = URI.fromTemplate(args.uri);
    return query.findAll<T>(this.with);
  }

  protected prepareQuery(): SimpleRxRestQuery {
    const query = this.client.createQuery(this.entity.getConstructor());
    return query;
  }
}
