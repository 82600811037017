import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class HalClientConfig {
  abstract prepareUrl(url: string): Observable<string>;
}

@Injectable()
export class HalClientFakeConfig extends HalClientConfig {
  prepareUrl(url: string): Observable<string> {
    return of('http://example.org').pipe(map(base => base + url));
  }
}
