import { RxRestEntityObjectAssignHydrator } from './rx-rest-entity-object-assign-hydrator';
import { HydratorInterface } from './hydrator.interface';
import { getUniqueConstructorIdentifier } from '../util';

export class StaticRxRestEntityHydratorRegistry {
  private static registry: { [name: string]: any } = {};

  static set(constructor: any, value: HydratorInterface) {
    const key = getUniqueConstructorIdentifier(constructor);
    this.registry[key] = value;
  }

  static get(constructor: any): HydratorInterface {
    const key = getUniqueConstructorIdentifier(constructor);
    if (!this.registry[key]) {
      this.registry[key] = new RxRestEntityObjectAssignHydrator();
    }

    return this.registry[key];
  }
}
