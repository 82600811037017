import { ParamValues, ParamValuesInterface } from './models/param';
import { Settings, SettingsInterface } from './models/settings';
import { FilterValues } from './models/filter';
import { SortingValues } from './models/sorting';
import { BehaviorSubject, Subject, Subscription, AsyncSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PagesInfo } from './models/pages-info';
import { PhpUrlEncoder } from './url-encoder/php';
import { deepEquals, deepCopy } from './models/utils';

export interface ScrollingStyleInterface {
  getPages(dataList: NgxDataList<any>, pageRange?: number): number[];
}

export interface UrlEncoderInterface {
  encodeParams(params: ParamValues<any>): any;
}

export class NgxDataList<T> {

  private _settings: Settings<T> = new Settings();
  private _data: T[] = [];
  private _params: ParamValues<T> = new ParamValues<T>();
  private prevParams: ParamValues<T>;

  private _loading = false;

  reload$: Subject<NgxDataList<T>> = new Subject<NgxDataList<T>>();
  dataLoaded$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>(null);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  loadingAsync$: Subscription;

  parametersChanged$: BehaviorSubject<{params: ParamValues<T>, shouldReload: boolean}> =
  new BehaviorSubject({params: this._params, shouldReload: false});

  private _asyncLoadingSubject: AsyncSubject<boolean>;
  private _reloadSubscription: Subscription;
  private _autoreloadSubscription: Subscription;

  constructor(
    baseParameters: ParamValuesInterface = {},
    settings?: SettingsInterface<T>
  ) {
    this.parameters(baseParameters);
    this.settings(settings);
    this.init();
  }

  get data(): T[] {
    return this._data;
  }

  get loading(): boolean {
    return this._loading;
  }

  protected init() {
    if (!this._settings.getData) {
      return;
    }

    this._reloadSubscription = this.reload$
    .pipe(tap(_ => {
      this._loading = true;
      this.loading$.next(true);
      this._asyncLoadingSubject = new AsyncSubject();
      this.loadingAsync$ = this._asyncLoadingSubject.subscribe();
      this.prevParams = this.createComparableParams();
     }))
    .pipe(this._settings.getData)
    .subscribe(data => {
      this._data = data;
      this.dataLoaded$.next(data);
      this._loading = false;
      this.loading$.next(false);
      this._asyncLoadingSubject.complete();
    });

    if (this.settings().autoreload === 'never') {
      this.reload();
    }

    this._autoreloadSubscription = this.parametersChanged$
    .subscribe(data => {
        switch (this.settings().autoreload) {
          case 'never':
            return;
          case 'auto':
            return data.shouldReload && this.reload();
          default:
            return this.reload();
        }
      });
  }

  parameters(): ParamValues<T>;
  parameters(newParameters: ParamValuesInterface): this;
  parameters(newParameters?: ParamValuesInterface) {
    if (newParameters === undefined) {
      return this._params;
    }

    // if count or filter is changed, auto page 1
    if (!newParameters['page'] && (newParameters['count'] || newParameters['filter'])) {
      newParameters['page'] = 1;
    }

    Object.assign(this._params, newParameters);
    this.parametersChanged$.next({params: this._params, shouldReload: this.isDataReloadRequired()});
    return this;
  }

  settings(): Settings<T>;
  settings(settings: SettingsInterface<T>): this;
  settings(settings?: SettingsInterface<T>) {
    if (settings === undefined) {
      return this._settings;
    }

    this._settings = Settings.merge(this._settings, settings);
    return this;
  }

  page(): number;
  page(page: number): this;
  page(page?: number) {
    return page !== undefined ? this.parameters({'page': page}) : this._params.page;
  }

  total(): number;
  total(total: number): this;
  total(total?: number) {
    return total !== undefined ? this.settings({'total': total}) : this._settings.total;
  }

  count(): number;
  count(count: number): this;
  count(count?: number) {
    return count !== undefined ? this.parameters({'count': count, 'page': 1}) : this._params.count;
  }

  filter(): FilterValues;
  filter(filter: FilterValues): this;
  filter(filter?: FilterValues) {
    if (filter === undefined) {
      return this._params.filter;
    }

    return this.parameters({
      'filter': filter,
      'page': 1
    });
  }

  sorting(): SortingValues;
  sorting(sorting: SortingValues): this;
  sorting(sorting?: SortingValues) {
    if (sorting === undefined) {
      return this._params.sorting;
    }

    return this.parameters({
      'sorting': sorting
    });
  }

  pageCount(): number {
    const totalItems = this.total();
    const pageSize = this.count();
    if (pageSize === 0) {
      return 0;
    }

    return Math.ceil(totalItems / pageSize);
  }

  isDataReloadRequired(): boolean {
    return !deepEquals(this.prevParams, this.createComparableParams());
  }

  private createComparableParams() {
    return deepCopy(this._params);
  }

  normalizePageNumber(pageNumber: number): number {
    if (pageNumber < 1) {
      pageNumber = 1;
    }

    const pageCount = this.pageCount();
    if (pageCount > 0 && pageNumber > pageCount) {
      pageNumber = pageCount;
    }

    return pageNumber;
  }

  getPagesInRange(lowerBound: number, upperBound: number): number[] {
    lowerBound = this.normalizePageNumber(lowerBound);
    upperBound = this.normalizePageNumber(upperBound);

    const pages = {};
    for (let pageNumber = lowerBound; pageNumber <= upperBound; pageNumber++) {
      pages[pageNumber] = pageNumber;
    }

    return Object.keys(pages).map(key => pages[key]);
  }

  getPages(ctor: { new (...args: any[]): ScrollingStyleInterface; }): PagesInfo {

    const pageCount = this.pageCount();
    const currentPageNumber = this.page();

    const pages = new PagesInfo();
    pages.pageCount = pageCount;
    pages.itemCountPerPage = this.count();
    pages.first = 1;
    pages.current = currentPageNumber;
    pages.last = pageCount;

    if (currentPageNumber - 1 > 0) {
      pages.previous = currentPageNumber - 1;
    }

    if (currentPageNumber + 1 <= pageCount) {
      pages.next = currentPageNumber + 1;
    }

    const scrollingStyle = new ctor();
    pages.pagesInRange = scrollingStyle.getPages(this);
    pages.firstPageInRange = Math.min(...pages.pagesInRange);
    pages.lastPageInRange = Math.max(...pages.pagesInRange);

    return pages;
  }

  reload(): void {
    this.reload$.next(this);
  }

  url(ctor?: { new (...args: any[]): UrlEncoderInterface; }): any {
    if (!ctor) {
      ctor = PhpUrlEncoder;
    }

    return (new ctor()).encodeParams(this._params);
  }

  destroy(): void {
    this._loading = false;

    if (this._autoreloadSubscription) {
      this._autoreloadSubscription.unsubscribe();
    }

    if (this._reloadSubscription) {
      this._reloadSubscription.unsubscribe();
    }
  }
}
