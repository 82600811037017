import { StrategyEnabledInterface } from './strategy-enabled.interface';
import { StrategyInterface } from './strategy/strategy.interface';

export abstract class AbstractRxRestEntityHydrator implements StrategyEnabledInterface {
  protected strategies = new Map<string, StrategyInterface>();

  getStrategy(name: string): StrategyInterface {
    return this.strategies.get(name);
  }

  hasStrategy(name: string): boolean {
    return this.strategies.has(name);
  }

  addStrategy(name: string, strategy: StrategyInterface): void {
    this.strategies.set(name, strategy);
  }

  removeStrategy(name: string): void {
    this.strategies.delete(name);
  }

  hydrateValue(name: string, value: any, data: any) {
    if (this.hasStrategy(name)) {
      const strategy = this.getStrategy(name);
      value = strategy.hydrate(value, data);
    }

    return value;
  }

  extractValue(name: string, value: any, object: any) {
    if (this.hasStrategy(name)) {
      const strategy = this.getStrategy(name);
      value = strategy.extract(value, object);
    }

    return value;
  }

}
