import { ScrollingStyleInterface, NgxDataList } from '../ngx-datalist';

export class ScrollingStyleSliding implements ScrollingStyleInterface {
  getPages(dataList: NgxDataList<any>, pageRange?: number): number[] {

    if (!pageRange) {
      pageRange = dataList.settings().pageRange;
    }

    const pageNumber = dataList.page();
    const pageCount = dataList.pageCount();

    if (pageRange > pageCount) {
      pageRange = pageCount;
    }

    let delta = Math.ceil(pageRange / 2);
    let lowerBound;
    let upperBound;

    if (pageNumber - delta > pageCount - pageRange) {
      lowerBound = pageCount - pageRange + 1;
      upperBound = pageCount;
    } else {
      if (pageNumber - delta < 0) {
        delta = pageNumber;
      }

      const offset = pageNumber - delta;
      lowerBound = offset + 1;
      upperBound = offset + pageRange;
    }

    return dataList.getPagesInRange(lowerBound, upperBound);
  }
}
