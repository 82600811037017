import { ParamValues } from '../models/param';
import { UrlDecoderInterface } from './decoder.interface';

export class AngularRouterUrlDecoder implements UrlDecoderInterface {
  decodeParams(params: any, strict = true, uriDecode = false): any {
    const whitelist = ['page', 'count'];
    const decodedParams = {
      'filter': {},
      'sorting': {}
    };

    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }

      const value = uriDecode ? decodeURIComponent(params[key]) : params[key];
      const match = key.match(/^(filter|sorting)\[(.*)\]$/i);

      if (!match && strict) {
        if (whitelist.indexOf(key) !== -1) {
          decodedParams[key] = parseInt(value, 10);
        }
        continue;
      }

      if (!match) {
        decodedParams[key] = value;
        continue;
      }

      const type = uriDecode ? decodeURIComponent(match[1]) : match[1];
      if (typeof decodedParams[type] !== 'object') {
        decodedParams[type] = {};
      }

      const subkey = uriDecode ? decodeURIComponent(match[2]) : match[2];
      decodedParams[type][subkey] = value;
    }

    return decodedParams;
  }
}
