import { RestEndpointPersistentEntity } from '../models/rest-endpoint-persistent-entity.class';
import { HalRequestOptions } from '../../hal/hal-client.service';

export class EntityOperation {
  // final Serializable identity
  object: Object;
}


export class BatchOperation {

  deletes: Map<RestEndpointPersistentEntity<any>, Array<{url: string, instance: Object, options?: HalRequestOptions}>> = new Map();
  updates: Map<RestEndpointPersistentEntity<any>, Array<{url: string, instance: Object, options?: HalRequestOptions}>> = new Map();
  inserts: Map<RestEndpointPersistentEntity<any>, Array<{url: string, instance: Object, options?: HalRequestOptions}>> = new Map();

  addDelete(entity: RestEndpointPersistentEntity<any>, object: {url: string, instance: Object, options?: HalRequestOptions}) {
    if (!this.deletes.has(entity)) {
      this.deletes.set(entity, []);
    }

    this.deletes.get(entity).push(object);
  }

  addUpdate(entity: RestEndpointPersistentEntity<any>, object: {url: string, instance: Object, options?: HalRequestOptions}) {
    if (!this.updates.has(entity)) {
      this.updates.set(entity, []);
    }

    this.updates.get(entity).push(object);
  }

  addInsert(entity: RestEndpointPersistentEntity<any>, object: {url: string, instance: Object, options?: HalRequestOptions}) {
    if (!this.inserts.has(entity)) {
      this.inserts.set(entity, []);
    }

    this.inserts.get(entity).push(object);
  }

  hasPendingRequests() {
    return this.inserts.size || this.updates.size || this.deletes.size;
  }

}
