export interface HalResourceEmbeddedInterface {
  [x: string]: any;
}

export interface HalResourceLinkInterface {
  href?: string;
  [x: string]: any;
}

export interface HalResourceLinksInterface {
  [x: string]: HalResourceLinkInterface;
}

export interface HalResourceInterface {
  [x: string]: any;
  _embedded?: HalResourceEmbeddedInterface;
  _links?: HalResourceLinksInterface;
}

export class HalResource implements HalResourceInterface {

  [x: string]: any;
  _embedded: HalResourceEmbeddedInterface = {};
  _links: HalResourceLinksInterface = {};

  static instanceFromData(data: HalResourceInterface) {

    if (null === data) {
      data = {
        _embedded: {},
        _links: {}
      };
    }

    if (data._embedded === undefined || data._embedded === null) {
      data._embedded = {};
    }

    if (data._links === undefined || data._links === null) {
      data._links = {};
    }

    for (const key in data._embedded) {

      // property does not exist
      if (!data._embedded.hasOwnProperty(key)) {
        continue;
      }

      // embedded array
      if (data._embedded[key] instanceof Array) {
        for (const key2 in data._embedded[key]) {
          if (!data._embedded[key][key2]) {
            continue;
          }

          data._embedded[key][key2] = this.instanceFromData(data._embedded[key][key2]);
        }

        continue;
      }

      // embedded object
      data._embedded[key] = this.instanceFromData(data._embedded[key]);
    }

    const instance = new this();
    Object.assign(instance, data);

    return instance;
  }

  getEmbedded(): any {
    return this._embedded;
  }

  getEmbeddedKeys(): string[] {
    return Object.keys(this._embedded);
  }

  getLinks(): any {
    return this._links;
  }

  getLinkKeys(): string[] {
    return Object.keys(this._links);
  }

  hasLink(key): boolean {
    return this._links[key] !== undefined && this._links !== null;
  }

  getLink(key): HalResourceLinkInterface {
    return this._links[key];
  }
}
